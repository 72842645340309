import React from 'react';
import { RedocStandalone } from 'redoc';
import theme from './theme.json';
import './App.css';


function App() {


  return (
    <div className="App">
    
     <RedocStandalone
     specUrl={`${process.env.PUBLIC_URL}/spec.yaml`}
  options={{
    hideLoading: true,
    hideDownloadButton: true,
    // schemaExpansionLevel: 'all',
    labels: {
      example: 'Ejemplo',
      examples: 'Ejemplos',
      responses: 'Respuestas',
      requestSamples: 'Ejemplos de solicitud',
      responseSamples: 'Ejemplos de respuesta',
    },
    nativeScrollbars: true,
    theme,

  }}
/>
    </div>
  );


}

export default App;


